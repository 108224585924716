import SetlistForm from './SetlistForm';

const CreateSetlistForm = () => {
  const setlist = {
    name: ''
  };
  return <SetlistForm setlist={setlist} />;
};

export default CreateSetlistForm;
