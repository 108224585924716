import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ModalProvider, Modal } from './context/Modal';
import configureStore from './store';
import * as sessionActions from './store/session';
import * as propActions from './store/props';
import * as setlistActions from './store/setlists';
import * as categoryActions from './store/categories';
import * as prophouseActions from './store/prophouses';
import App from './App';

import './index.css';

const store = configureStore();

if (process.env.NODE_ENV !== 'production') {
  window.store = store;
  window.sessionActions = sessionActions;
  window.propActions = propActions;
  window.setlistActions = setlistActions;
  window.categoryActions = categoryActions;
  window.prophouseActions = prophouseActions;
}

// Wrap the application with the Modal provider and render the Modal component
// after the App component so that all the Modal content will be layered as
// HTML elements on top of the all the other HTML elements:
function Root() {
  return (
    <ModalProvider>
      <Provider store={store}>
        <BrowserRouter>
          <App />
          <Modal />
        </BrowserRouter>
      </Provider>
    </ModalProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);
